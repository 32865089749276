export default {
	state: {
		outstanding: { total: 0 },
		missingReferences: { total: 0 }
	},
	mutations: {
		updateOutstandingRequests(state, outstanding) {
			state.outstanding = outstanding;
		},
		updateMissingReferences(state, missingReferences) {
			state.missingReferences = missingReferences;
		}
	}
}