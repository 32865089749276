import { h, resolveComponent } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

import store from '../store';

let scopes = [];
if (localStorage.auth && localStorage.auth.type !== null) {
	const tempScopes = JSON.parse(localStorage.auth).scopes;
	if (tempScopes) {
		scopes = tempScopes;
		store.commit('updateScopes', scopes);
	}
}

function hasScope(scopes, scope) {
	return scopes.includes(scope);
}

const routes = [
	{
		path: '/',
		name: 'Index',
		meta: { title: 'Index' },
		redirect: 'dashboard',
		component: () => import('../layouts/Admin'),
		beforeEnter: () => {
			if (!localStorage.auth) {
				router.push({ name: 'Login' });
				return false;
			}
		},
		children: [
			{
				path: '/dashboard',
				name: 'Dashboard',
				meta: { title: 'Dashboard', icon: 'square-poll-vertical' },
				component: () => import('../views/Dashboard')
			},
			{
				path: '/profile',
				name: 'Profile',
				meta: { title: 'Profile', icon: 'address-card' },
				component: () => import('../views/Profile')
			},
			{
				path: '/requests',
				meta: { title: 'Requests', icon: 'file' },
				component: {
					render() {
						return h(resolveComponent('router-view'));
					}
				},
				children: [
					{
						path: '',
						name: 'Requests',
						component: () => hasScope(scopes, 'requests::list') ? import('../views/Requests/Index') : router.push({ name: 'Dashboard' }),
					},
					{
						path: 'outstanding',
						meta: { title: 'Outstanding', icon: 'triangle-exclamation' },
						component: {
							render() {
								return h(resolveComponent('router-view'));
							}
						},
						children: [
							{
								path: '',
								name: 'RequestsOutstanding',
								redirect: {
									name: 'Requests', query: {
										tab: 'outstanding'
									}
								},
							},
							{
								path: ':id',
								name: 'RequestsOutstandingView',
								meta: { title: 'View Request', icon: 'eye', type: 'in-progress' },
								component: () => hasScope(scopes, 'requests::list') ? import('../views/Requests/Show') : router.push({ name: 'Dashboard' })
							}
						]
					},
					{
						path: 'in-progress',
						meta: { title: 'In Progress', icon: 'rotate' },
						component: {
							render() {
								return h(resolveComponent('router-view'));
							}
						},
						children: [
							{
								path: '',
								name: 'RequestsInProgress',
								redirect: {
									name: 'Requests', query: {
										tab: 'in-progress'
									}
								},
							},
							{
								path: ':id',
								name: 'RequestsInProgressView',
								meta: { title: 'View Request', icon: 'eye', type: 'in-progress' },
								component: () => hasScope(scopes, 'requests::list') ? import('../views/Requests/Show') : router.push({ name: 'Dashboard' })
							}
						]
					},
					{
						path: 'completed',
						meta: { title: 'Completed', icon: 'circle-check' },
						component: {
							render() {
								return h(resolveComponent('router-view'));
							}
						},
						children: [
							{
								path: '',
								name: 'RequestsCompleted',
								redirect: {
									name: 'Requests', query: {
										tab: 'completed'
									}
								},
							},
							{
								path: ':id',
								name: 'RequestsCompletedView',
								meta: { title: 'View Request', icon: 'eye', type: 'in-progress' },
								component: () => hasScope(scopes, 'requests::list') ? import('../views/Requests/Show') : router.push({ name: 'Dashboard' })
							}
						]
					},
					{
						path: 'generate-:id',
						name: 'RequestsGenerate',
						meta: { title: 'Download Request', icon: 'download' },
						component: () => hasScope(scopes, 'requests::list') ? import('../views/Requests/Generate') : router.push({ name: 'Dashboard' })
					},
				]
			},
			{
				path: '/information',
				name: 'Information',
				meta: { title: 'Ad-hoc Client Information', icon: 'magnifying-glass' },
				component: () => hasScope(scopes, 'applications::list') ? import('../views/Information') : router.push({ name: 'Dashboard' })
			},
			{
				path: '/applications',
				meta: { title: 'Applications', icon: 'bolt' },
				component: {
					render() {
						return h(resolveComponent('router-view'));
					}
				},
				children: [
					{
						path: '',
						name: 'LiveClientListing',
						component: () => hasScope(scopes, 'applications::index') ? import('../views/Applications/Index') : router.push({ name: 'Dashboard' })
					},
					{
						path: ':id',
						meta: { title: 'ApplicationShow', icon: 'hashtag' },
						component: {
							render() {
								return h(resolveComponent('router-view'));
							}
						},
						children: [
							{
								path: '',
								name: 'ApplicationShow',
								component: () => import('../views/Applications/Show')
							},
							{
								path: ':debt_id',
								name: 'ApplicationOffersShow',
								component: () => import('../views/Applications/Offers/Show')
							}
						]
					}
				]
			},
			{
				path: '/remittance',
				name: 'RemittanceFiles',
				meta: { title: 'Remittance Files', icon: 'file-invoice-dollar' },
				component: () => hasScope(scopes, 'remittances::list') ? import('../views/Remittance') : router.push({ name: 'Dashboard' })
			},
			{
				path: '/query',
				name: 'SendMoneyPlusAQuery',
				meta: { title: 'Send MoneyPlus a Query', icon: 'paper-plane' },
				component: () => hasScope(scopes, 'queries::list') ? import('../views/Query') : router.push({ name: 'Dashboard' })
			},
			{
				path: '/missing-references',
				name: 'MissingReferences',
				meta: { title: 'Incorrect/Missing Debt References', icon: 'hashtag' },
				component: () => hasScope(scopes, 'debts::list') ? import('../views/MissingReferences') : router.push({ name: 'Dashboard' })
			},
			{
				path: '/returned-payments',
				name: 'ReturnedPayments',
				meta: { title: 'Returned Payments', icon: 'hashtag' },
				component: () => hasScope(scopes, 'investigations::list') ? import('../views/ReturnedPayments') : router.push({ name: 'Dashboard' })
			},
			{
				path: '/users',
				meta: { title: 'Users', icon: 'user-group' },
				component: {
					render() {
						return h(resolveComponent('router-view'));
					}
				},
				children: [
					{
						path: '',
						name: 'Users',
						component: () => hasScope(scopes, 'admin') ? import('../views/Admin/Users/Index') : router.push({ name: 'Dashboard' })
					},
					{
						path: 'create',
						name: 'UserCreate',
						meta: { title: 'Create User', icon: 'circle-plus' },
						component: () => hasScope(scopes, 'admin') ? import('../views/Admin/Users/Create') : router.push({ name: 'Dashboard' })
					},
					{
						path: ':id',
						name: 'UserEdit',
						meta: { title: 'Edit User', icon: 'pen-to-square' },
						component: () => hasScope(scopes, 'admin') ? import('../views/Admin/Users/Create') : router.push({ name: 'Dashboard' })
					}
				]
			},
			{
				path: '/creditors',
				meta: { title: 'Creditors', icon: 'building-columns' },
				component: {
					render() {
						return h(resolveComponent('router-view'));
					}
				},
				children: [
					{
						path: '',
						name: 'Creditors',
						component: () => hasScope(scopes, 'admin') ? import('../views/Admin/Creditors/Index') : router.push({ name: 'Dashboard' })
					},
					{
						path: ':id/edit',
						name: 'CreditorEdit',
						meta: { title: 'Edit Creditor', icon: 'file-pen', name: ':name' },
						component: () => hasScope(scopes, 'admin') ? import('../views/Admin/Creditors/Edit') : router.push({ name: 'Dashboard' })
					},
					{
						path: ':id/preferences',
						name: 'CreditorPreferences',
						meta: { title: 'Edit Template Preferences', icon: 'pen-to-square', name: ':name' },
						component: () => hasScope(scopes, 'admin') ? import('../views/Admin/Creditors/Preferences') : router.push({ name: 'Dashboard' })
					}
				]
			}
		]
	},
	{
		path: '/login',
		name: 'Login',
		meta: { title: 'Login' },
		component: () => import('../views/Auth/Login'),
		beforeEnter: () => {
			if (store.state.auth.access_token) {
				router.push({ name: 'Dashboard' });
				return false;
			}
		}
	},
	{
		path: '/logout',
		name: 'Logout',
		meta: { title: 'Logout' },
		component: {
			render() {
				this.$store.dispatch('resetStore');
				this.$router.push({ name: 'Login' });
			}
		}
	},
	{
		path: '/forgot-password',
		name: 'ForgotPassword',
		meta: { title: 'Forgot Password' },
		component: () => import('../views/Auth/ForgotPassword'),
		beforeEnter: () => store.dispatch('resetStore')
	},
	{
		path: '/reset-password',
		name: 'ResetPassword',
		meta: { title: 'Reset Password' },
		component: () => import('../views/Auth/ResetPassword'),
		beforeEnter: () => store.dispatch('resetStore')
	},
	{ path: '/:pathMatch(.*)*', redirect: { name: 'Login' } }
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;