<template>
	<ul class="flex items-center -space-x-px h-8 text-sm" v-if="totalPages > 1">
		<li>
			<button :disabled="isInFirstPage" @click="onClickFirstPage"
				:class="`flex items-center justify-center px-3 h-8 ml-0 leading-tight bg-slate-100 dark:bg-slate-600 border border-slate-300 dark:border-slate-800 active:text-white dark:active:text-gray-200 active:bg-indigo-400 dark:active:bg-indigo-500 min-w-6 rounded-l-lg ${isInFirstPage ? 'text-gray-300 dark:text-gray-500 cursor-not-allowed' : 'text-gray-600 dark:text-gray-400 hover:bg-slate-200 dark:hover:bg-slate-500 hover:text-gray-700 dark:hover:text-gray-300'}`">
				<fa icon="angles-left" />
			</button>
		</li>
		<li>
			<button :disabled="isInFirstPage" @click="onClickPreviousPage"
				:class="`flex items-center justify-center px-3 h-8 leading-tight bg-slate-100 dark:bg-slate-600 border border-slate-300 dark:border-slate-800 active:text-white dark:active:text-gray-200 active:bg-indigo-400 dark:active:bg-indigo-500 min-w-6 ${isInFirstPage ? 'text-gray-300 dark:text-gray-500 cursor-not-allowed' : 'text-gray-600 dark:text-gray-400 hover:bg-slate-200 dark:hover:bg-slate-500 hover:text-gray-700 dark:hover:text-gray-300'}`">
				<fa icon="angle-left" />
			</button>
		</li>
		<li :key="page.name" class="pagination-item inline-block" v-for="page in pages">
			<button :disabled="page.isDisabled" @click="onClickPage(page.name)"
				:class="`flex items-center justify-center px-3 h-8 leading-tight bg-slate-100 dark:bg-slate-600 border border-slate-300 dark:border-slate-800 active:text-white dark:active:text-gray-200 active:bg-indigo-400 dark:active:bg-indigo-500 min-w-6 hover:bg-slate-200 dark:hover:bg-slate-500 dark:text-gray-200 ${isPageActive(page.name) ? 'active' : ''}`">
				{{ page.name }}
			</button>
		</li>
		<li>
			<button :disabled="isInLastPage" @click="onClickNextPage"
				:class="`flex items-center justify-center px-3 h-8 leading-tight bg-slate-100 dark:bg-slate-600 border border-slate-300 dark:border-slate-800 active:text-white dark:active:text-gray-200 active:bg-indigo-400 dark:active:bg-indigo-500 min-w-6 ${isInLastPage ? 'text-gray-300 dark:text-gray-500 cursor-not-allowed' : 'text-gray-600 dark:text-gray-400 hover:bg-slate-200 dark:hover:bg-slate-500 hover:text-gray-700 dark:hover:text-gray-300'}`">
				<fa icon="angle-right" />
			</button>
		</li>
		<li>
			<button :disabled="isInLastPage" @click="onClickLastPage"
				:class="`flex items-center justify-center px-3 h-8 leading-tight bg-slate-100 dark:bg-slate-600 border border-slate-300 dark:border-slate-800 active:text-white dark:active:text-gray-200 active:bg-indigo-400 dark:active:bg-indigo-500 min-w-6 rounded-r-lg ${isInLastPage ? 'text-gray-300 dark:text-gray-500 cursor-not-allowed' : 'text-gray-600 dark:text-gray-400 hover:bg-slate-200 dark:hover:bg-slate-500 hover:text-gray-700 dark:hover:text-gray-300'}`">
				<fa icon="angles-right" :class="totalPages > 6 ? 'mr-1' : ''" />{{ totalPages > 6 ? `${totalPages}` : '' }}
			</button>
		</li>
	</ul>
</template>

<script>
export default {
	emits: ['pageChanged'],
	props: {
		maxVisibleButtons: {
			type: Number,
			required: false,
			default: 5
		},
		totalPages: {
			type: Number,
			required: true
		},
		perPage: {
			type: Number,
			required: true
		},
		currentPage: {
			type: Number,
			required: true
		}
	},
	computed: {
		startPage() {
			// When on the first page
			if (this.currentPage === 1) {
				return 1;
			}
			// When on the last page
			if (this.currentPage === this.totalPages) {
				return this.totalPages - this.maxVisibleButtons;
			}
			// When inbetween
			return this.currentPage - 1;
		},
		pages() {
			const range = [];

			for (
				let i = this.startPage;
				i <= Math.min(this.startPage + this.maxVisibleButtons, this.totalPages);
				i++
			) {
				if (i > 0) {
					range.push({
						name: i,
						isDisabled: i === this.currentPage
					});
				}
			}

			return range;
		},
		isInFirstPage() {
			return this.currentPage === 1;
		},
		isInLastPage() {
			return this.currentPage === this.totalPages;
		}
	},
	methods: {
		onClickFirstPage() {
			this.$emit('pageChanged', 1);
		},
		onClickPreviousPage() {
			this.$emit('pageChanged', this.currentPage - 1);
		},
		onClickPage(page) {
			this.$emit('pageChanged', page);
		},
		onClickNextPage() {
			this.$emit('pageChanged', this.currentPage + 1);
		},
		onClickLastPage() {
			this.$emit('pageChanged', this.totalPages);
		},
		isPageActive(page) {
			return this.currentPage === page;
		}
	}
};
</script>

<style lang="scss" scoped>
.pagination-item button.active {
	background-color: #818cf8;
	color: #fff;

	@media (prefers-color-scheme: dark) {
		background-color: #6366f1;
		color: #e5e7eb;
	}
}
</style>