import { createStore } from 'vuex';

import auth from './auth';

import creditor from './creditor';

import user from './user';

import notifications from './notifications';

export default createStore({
	state: {
		env: process.env,
		updateCount: 0,
		activePage: 'page',
		toasts: []
	},
	getters: {
		getEnv: (state) => (key = undefined) => {
			if (key) return state.env[key];
			return state.env;
		}
	},
	mutations: {
		incrementStoreUpdateCount(state) {
			state.updateCount++;
		},
		updateCurrentPageTitle(state, { title }) {
			state.activePage = title;
		},
		addToast(state, toast) {
			state.toasts.push(toast);
		},
		clearToast(state, title) {
			const index = state.toasts.findIndex((toast) => toast.title === title);
			state.toasts.splice(index, 1);
		}
	},
	actions: {
		loadStore(context) {
			context.commit('loadAuthToken');
			context.commit('loadCreditor');
		},
		resetStore(context) {
			context.commit('resetAuthTokens');
			context.commit('resetUser');
		}
	},
	modules: {
		auth,
		creditor,
		user,
		notifications
	}
})