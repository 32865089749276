<template>
	<Select2 :options="options" :settings="settings" @update:modelValue="update" class="new-select" v-model="selectedValue"
		@select="selected" />
</template>

<script>
import Select2 from 'vue3-select2-component';

export default {
	components: { Select2 },
	emits: ['input', 'selected'],
	props: {
		options: { required: true, type: Array },
		multiple: { type: Boolean, default: false },
		value: [Number, String, Array]
	},
	watch: {
		value(newValue) {
			this.selectedValue = newValue;
		}
	},
	data() {
		return {
			settings: { allowClear: true, multiple: this.multiple },
			selectedValue: this.value,
		}
	},
	methods: {
		update(value) {
			this.$emit('input', value);
		},
		selected(value) {
			this.$emit('selected', value);
		}
	}
}
</script>

<style scoped>
.new-select>>>.select2-container {
	width: 100% !important;
}

.creditor-select-nav-bar>>>.select2-selection--single {
	min-height: 44px;
}

.creditor-select-nav-bar>>>.select2-container {
	width: calc(100% + 4px) !important;
	right: 2px;
}

.new-select>>>.select2-selection--single {
	padding: 0.5rem !important;
}

.new-select>>>.select2-selection__arrow {
	top: 0.5rem !important;
	right: .6rem !important;
}
</style>