<template>
	<date-picker :max="settings.max" :range="range" auto-submit format="YYYY-MM-DD" locale="en" v-model="selected" />
</template>

<script>
import Vue3PersianDatetimePicker from 'vue3-persian-datetime-picker';

import { misc } from '../mixins';

export default {
	emits: ['input'],
	mixins: [misc],
	components: { DatePicker: Vue3PersianDatetimePicker },
	props: {
		range: { type: Boolean, default: false },
		from: { type: String, default: null },
		to: { type: String, default: null },
		date: { type: String, default: null }
	},
	watch: {
		from(newValue) {
			this.selected = [this.getDateFormatFromDatetime(newValue), this.to];
		},
		to(newValue) {
			this.selected = [this.from, this.getDateFormatFromDatetime(newValue)];
		},
		date(newValue) {
			this.selected = this.getDateFormatFromDatetime(newValue);
		},
		selected(newValue) {
			if (this.range) {
				const [from, to] = newValue;
				this.$emit('input', { from, to });
			} else {
				this.$emit('input', newValue);
			}
		}
	},
	data() {
		return {
			settings: {
				max: this.getDateFormatFromDatetime()
			},
			selected: null
		}
	},
	mounted() {
		if (this.range) {
			let from = null;
			if (this.from) {
				from = this.getDateFormatFromDatetime(this.from);
			}
			let to = null;
			if (this.to) {
				to = this.getDateFormatFromDatetime(this.to);
			}
			if (from && to) {
				this.selected = [from, to];
			}
		} else {
			if (this.date) {
				this.selected = this.getDateFormatFromDatetime(this.date);
			}
		}
	}
}
</script>