export default {
	state: {
		creditor: null,
		creditors: []
	},
	mutations: {
		updateCreditor(state, creditor) {
			state.creditor = creditor;
			localStorage.setItem('creditor', JSON.stringify(state.creditor))
		},
		updateCreditors(state, creditors) {
			state.creditors = creditors;
		},
		loadCreditor(state) {
			let Creditor = null;
			if (localStorage.getItem('creditor')) {
				Creditor = localStorage.getItem('creditor');
			}
			Creditor = JSON.parse(Creditor);
			if (Creditor) {
				state.creditor = Creditor;
			}
		},
		resetCreditor(state) {
			localStorage.removeItem('creditor');
			state.creditor = null;
		}
	}
}