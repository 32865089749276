<template>
	<div>
		<div class="relative flex flex-col min-w-0 break-words w-full bg-transparent overflow-x-auto">
			<div v-if="exportable" class="flex">
				<div class="flex space-x-2 mb-2 border-0 ml-auto">
					<button @click="$store.commit('incrementStoreUpdateCount')"
						class="refresh-btn border-2 border-indigo-400 text-indigo-400 dark:border-indigo-600 dark:text-indigo-600 hover:text-indigo-300 hover:dark:text-indigo-500 hover:border-indigo-300 hover:dark:border-indigo-500 font-medium text-sm px-4 py-2 rounded-lg outline-none"
						type="button">
						<fa icon="sync" class="mr-2" />
						<span>Refresh</span>
					</button>
					<button @click="loadData(true)" id="exportBtn" type="button" :disabled="loading"
						class="text-white dark:text-gray-200 px-[1.15rem] py-2.5 font-medium rounded-lg text-sm bg-blue-500 disabled:bg-blue-300 dark:bg-blue-600 dark:disabled:bg-blue-400 hover:bg-blue-400 hover:dark:bg-blue-500 disabled:cursor-not-allowed">
						<fa icon="file-export" class="mr-2" />Export
					</button>
				</div>
			</div>
			<table class="items-center w-full bg-transparent border-collapse">
				<thead>
					<tr>
						<th
							class="sticky left-0 rounded-tl-lg text-sm px-8 py-2.5 text-center uppercase whitespace-nowrap font-semibold text-gray-500 dark:text-gray-400 bg-slate-200 dark:bg-slate-600"
							v-if="showActionColumn && (spreadsheet === 'Offers' || spreadsheet === 'Balance Request' || spreadsheet === 'Creditor Query')">
							{{ actions.length > 1 ? 'Actions' : 'Action' }}
						</th>
						<th :id="`col-${index}`" :key="index" :style="{ width: column.width ? column.width : 'auto' }"
							:class="`first:rounded-tl-lg last:rounded-tr-lg text-sm px-5 py-3 uppercase whitespace-nowrap font-semibold text-left text-gray-500 dark:text-gray-400 bg-slate-200 dark:bg-slate-600 ${column.center ? 'text-center' : ''} ${stickyIfSpreadsheet(spreadsheet, column.label)}`"
							v-for="(column, index) in columns">
							{{ column.label }}
						</th>
						<th :key="index"
							:class="`first:rounded-tl-lg last:rounded-tr-lg text-sm px-5 py-2.5 uppercase whitespace-nowrap font-semibold text-center text-gray-500 dark:text-gray-400 bg-slate-200 dark:bg-slate-600 ${column.center ? 'text-center' : ''}`"
							v-for="(column, index) in withCount">
							{{ column }}
						</th>
						<th
							class="rounded-tr-lg text-sm px-5 py-2.5 text-center uppercase whitespace-nowrap font-semibold text-gray-500 dark:text-gray-400 bg-slate-200 dark:bg-slate-600"
							v-if="showActionColumn && !spreadsheet">
							{{ actions.length > 1 ? 'Actions' : 'Action' }}
						</th>
					</tr>
					<tr v-if="filterable"
						class="bg-white dark:bg-slate-500 px-5 align-middle border border-t-0 border-solid dark:border-x-0 dark:border-slate-600">
						<td class="rounded-none" :key="index" v-for="(column, index) in columns">
							<div class="flex justify-center m-2" v-if="column.filter">
								<select v-model="filters[column.name]"
									class="w-full rounded-lg focus:ring-0 focus:border-white dark:focus:border-slate-500 block text-xs px-2.5 py-1.5 bg-slate-100 dark:bg-slate-400 dark:text-gray-200 dark:placeholder:text-gray-200"
									v-if="column.filterType === 'boolean'">
									<option value="all" selected>All</option>
									<option value="yes">Yes</option>
									<option value="no">No</option>
								</select>
								<input :type="column.type ? column.type : 'text'" v-model="filters[column.name]" placeholder="Search..."
									class="w-full rounded-lg dark:rounded-lg focus:ring-0 focus:border-white dark:focus:border-slate-500 block text-xs dark:text-xs px-2.5 py-1.5 dark:py-1.5 bg-slate-100 dark:bg-slate-400 dark:text-gray-200 dark:placeholder:text-gray-200"
									v-else />
							</div>
						</td>
						<td v-if="showActionColumn"
							class="rounded-none bg-white dark:bg-slate-500 dark:text-gray-200 border-t-0 px-5 align-middle border-x-0 border-b-1 border border-solid dark:border-slate-600">
						</td>
					</tr>
					<tr v-else
						class="bg-white dark:bg-slate-500 dark:text-gray-200 border-t-0 m-2 px-5 align-middle border-x-0 border-b-1 border border-solid dark:border-slate-600">
					</tr>
				</thead>
				<tbody>
					<tr :key="rowIndex" v-for="(row, rowIndex) in table.data" class="bg-white dark:bg-slate-500">
						<td
							class="sticky left-0 px-5 py-2 bg-white dark:bg-slate-500 dark:text-gray-200 border-t-0 text-center border-x-0 border-b-1 border border-solid dark:border-slate-600 text-xs whitespace-nowrap w-1/5"
							v-if="showActionColumn && (spreadsheet === 'Offers' || spreadsheet === 'Balance Request' || spreadsheet === 'Creditor Query')">
							<button @click="tableAction('edit', row)"
								class="text-sm font-medium text-white dark:text-gray-200 bg-amber-400 dark:bg-amber-500 hover:bg-amber-500 hover:dark:bg-amber-400 px-3 py-1 rounded-lg"
								type="button" title="Edit" v-if="actions.includes('edit')">
								<fa icon="edit" />
							</button>
						</td>
						<td :key="colIndex"
							:class="`border-t-0 px-5 py-2 bg-white dark:bg-slate-500 dark:text-gray-200 align-middle border-x-0 border-b-1 border border-solid dark:border-slate-600 text-xs whitespace-nowrap ${column.center ? 'text-center' : ''} ${stickyIfSpreadsheet(spreadsheet, column.name)}`"
							v-for="(column, colIndex) in columns">
							<slot :name="column.name" v-bind="row">
								{{ row[column.name] }}
							</slot>
						</td>
						<td :key="colIndex"
							class="border-t-0 px-5 py-2 bg-white dark:bg-slate-500 dark:text-gray-200 align-middle text-center border-x-0 border-b-1 border border-solid dark:border-slate-600 text-xs whitespace-nowrap"
							v-for="(column, colIndex) in withCount">
							{{ row[column].length }}
						</td>
						<td
							class="border-t-0 px-5 py-2 text-center border-x-0 border-b-1 border border-solid dark:border-slate-600 text-xs whitespace-nowrap w-1/5"
							v-if="showActionColumn && !spreadsheet">
							<div class="inline-flex rounded-lg space-x-1.5 text-sm font-medium text-white dark:text-gray-200"
								role="group">
								<span class="border-r border-slate-200 dark:border-[#56657A] pr-2.5 mr-1.5"
									v-if="actions.includes('move-in-progress')">
									<button @click="tableAction('move-in-progress', row)"
										class="bg-purple-500 dark:bg-purple-600 hover:bg-purple-600 hover:dark:bg-purple-500 px-3 py-1 rounded-lg"
										type="button" title="Move to In Progress">
										<fa icon="arrow-right-from-bracket" />
									</button>
								</span>
								<span class="border-r border-slate-200 dark:border-[#56657A] pr-2.5 mr-1.5"
									v-if="actions.includes('move-back-to-in-progress')">
									<button @click="tableAction('move-back-to-in-progress', row)"
										class="bg-purple-500 dark:bg-purple-600 hover:bg-purple-600 hover:dark:bg-purple-500 px-3 py-1 rounded-lg"
										type="button" title="Move to In Progress">
										<fa icon="arrow-right-from-bracket" class="rotate-180" />
									</button>
								</span>
								<span class="border-r border-slate-200 dark:border-[#56657A] pr-2.5 mr-1.5"
									v-if="actions.includes('move-completed')">
									<button @click="tableAction('move-completed', row)"
										class="bg-purple-500 dark:bg-purple-600 hover:bg-purple-600 hover:dark:bg-purple-500 px-3 py-1 rounded-lg"
										type="button" title="Move to Completed">
										<fa icon="arrow-right-from-bracket" />
									</button>
								</span>
								<button @click="tableAction('show', row)" title="Open"
									class="bg-blue-500 dark:bg-blue-600 hover:bg-blue-600 hover:dark:bg-blue-500 px-3 py-1 rounded-lg"
									type="button" v-if="actions.includes('show')">
									<fa icon="eye" />
								</button>
								<button v-if="actions.includes('download') && !actions.includes('show')"
									@click="tableAction('download', row)" title="Download File"
									class="bg-sky-500 dark:bg-sky-600 hover:bg-sky-600 hover:dark:bg-sky-500 px-3 py-1 rounded-lg"
									type="button">
									<fa icon="download" />
								</button>
								<button v-if="actions.includes('download') && actions.includes('show') && !actions.includes('upload')"
									@click="tableAction('download', row)" title="Download All Files"
									class="bg-sky-500 dark:bg-sky-600 hover:bg-sky-600 hover:dark:bg-sky-500 px-3 py-1 rounded-lg"
									type="button">
									<fa icon="download" />
								</button>
								<button v-if="actions.includes('download') && actions.includes('show') && actions.includes('upload')"
									@click="tableAction('download', row)" title="Download File"
									class="bg-sky-500 dark:bg-sky-600 hover:bg-sky-600 hover:dark:bg-sky-500 px-3 py-1 rounded-lg"
									type="button">
									<fa icon="download" />
								</button>
								<button @click="tableAction('generate', row)" title="Download Custom"
									class="bg-orange-500 dark:bg-orange-600 hover:bg-orange-600 hover:dark:bg-orange-500 px-3 py-1 rounded-lg"
									type="button" v-if="actions.includes('generate')">
									<fa icon="download" />
								</button>
								<button @click="tableAction('upload', row)"
									class="bg-purple-500 dark:bg-purple-600 hover:bg-indigo-600 hover:dark:bg-indigo-500 px-3 py-1 rounded-lg"
									type="button" title="Upload File" v-if="actions.includes('upload')">
									<fa icon="upload" />
								</button>
								<button @click="tableAction('edit', row)"
									class="bg-amber-400 dark:bg-amber-500 hover:bg-amber-500 hover:dark:bg-amber-400 px-3 py-1 rounded-lg"
									type="button" title="Edit" v-if="actions.includes('edit')">
									<fa icon="edit" />
								</button>
								<button @click="tableAction('query', row)"
									class="bg-indigo-500 dark:bg-indigo-600 hover:bg-indigo-600 hover:dark:bg-indigo-500 px-3 py-1 rounded-lg"
									type="button" title="Send Query" v-if="actions.includes('query')">
									<fa icon="paper-plane" />
								</button>
								<button @click="tableAction('preferences', row)"
									class="bg-orange-400 dark:bg-orange-500 hover:bg-orange-500 hover:dark:bg-orange-400 px-3 py-1 rounded-lg"
									type="button" title="Change Template Preferences" v-if="actions.includes('preferences')">
									<fa icon="file-pen" />
								</button>
								<button @click="confirmDelete(rowIndex)"
									class="bg-red-500 dark:bg-red-600 hover:bg-red-600 hover:dark:bg-red-500 px-3 py-1 rounded-lg"
									title="Confirm Removal" type="button"
									v-if="actions.includes('delete') && !actionDeleteConfirm[rowIndex]">
									<fa icon="trash" />
								</button>
								<button @click="resetConfirmDelete(rowIndex)"
									class="bg-red-500 dark:bg-red-600 hover:bg-red-600 hover:dark:bg-red-500 px-3 py-1 rounded-lg"
									title="Decline Removal" type="button" v-if="actionDeleteConfirm[rowIndex]">
									<fa icon="xmark" />
								</button>
								<button @click="tableAction('delete', row); resetConfirmDelete(rowIndex);" title="Confirm Removal"
									class="bg-green-400 dark:bg-green-500 hover:bg-green-300 hover:dark:bg-green-400 px-3 py-1 rounded-lg"
									type="button" v-if="actionDeleteConfirm[rowIndex]">
									<fa icon="check" />
								</button>
								<button @click="tableAction('returns', row)" title="Specify Reason"
									class="bg-amber-400 dark:bg-amber-500 hover:bg-amber-500 hover:dark:bg-amber-400 px-3 py-1 rounded-lg"
									type="button" v-if="actions.includes('returns')">
									<fa icon="edit" />
								</button>
							</div>
						</td>
					</tr>
					<tr v-if="!table.data.length" class="bg-white dark:bg-slate-500 dark:text-white">
						<td :colspan="totalColumns" align="center" class="p-3 rounded-b-lg">
							<span v-if="loading">
								<fa icon="rotate" class="mr-2" />Loading...
							</span>
							<span v-else>
								<fa icon="circle-exclamation" class="mr-2" />No Results Found
							</span>
						</td>
					</tr>
					<span class="loader" v-if="loading"></span>
				</tbody>
			</table>
		</div>
		<div class="flex justify-end mt-2">
			<div v-if="pagination">
				<Pagination :per-page="table.per_page" :current-page="table.current_page" :total-pages="table.last_page"
					@pageChanged="pageChanged" class="text-end" />
			</div>
		</div>
	</div>
</template>

<script>
import { debounce } from 'lodash';

import Pagination from './Pagination.vue';

import { axios, misc } from '../mixins';

export default {
	mixins: [axios, misc],
	props: {
		api: { type: String, required: true, validator: function (value) { return ['users', 'creditors'].indexOf(value) !== -1; } },
		lazyLoading: { type: Boolean, default: false },
		pagination: { type: Boolean, default: true },
		exportable: { type: Boolean, default: false },
		endpoint: { type: String, required: true },
		spreadsheet: { type: String, default: null },
		params: { type: Object, default: () => { return {} } },
		columns: { type: Array, required: true },
		withCount: { type: Array, default: () => { return [] } },
		include: { type: Array, default: () => { return [] } },
		filterable: { type: Boolean, default: false },
		actions: { type: Array, default: () => { return [] } }
	},
	emits: ['action', 'update', 'error', 'sort'],
	components: { Pagination },
	data() {
		return {
			table: {
				current_page: 1,
				data: [],
				from: 1,
				to: 1,
				last_page: 1,
				per_page: 11,
				total: 1
			},
			loading: false,
			loadingText: 'No Results Found',
			filters: { query: null },
			actionDeleteConfirm: {}
		}
	},
	computed: {
		totalColumns() {
			let totalColumns = this.columns.length;
			if (this.withCount.length > 0) {
				totalColumns += this.withCount.length;
			}
			if (this.actions.length) {
				totalColumns++;
			}
			return totalColumns;
		},
		showActionColumn() {
			return this.actions.length;
		},
		showColumnFilters() {
			const filters = this.columns.filter(column => column.filter);
			return filters.length > 0;
		}
	},
	watch: {
		'$store.state.updateCount'() {
			this.loadData();
		},
		endpoint() {
			this.table = {
				current_page: 1,
				data: [],
				from: 1,
				to: 1,
				last_page: 1,
				total: 1
			}
		},
		filters: {
			handler() {
				this.filterChanged(this);
			},
			deep: true
		},
		params: {
			handler() {
				this.filterChanged(this);
			},
			deep: true
		}
	},
	mounted() {
		this.loadFilters();
		if (!this.lazyLoading) {
			this.loadData();
		}
	},
	methods: {
		tableAction(type, data) {
			this.$emit('action', { type, data });
		},
		loadFilters() {
			this.columns.forEach(column => this.filters[column.name] = null);
		},
		confirmDelete(rowIndex) {
			this.actionDeleteConfirm[rowIndex] = true;
		},
		resetConfirmDelete(rowIndex) {
			this.actionDeleteConfirm[rowIndex] = false;
		},
		async loadData(full) {
			this.loading = true;

			let _api = null;
			switch (this.api) {
				case 'users':
					_api = this._users_api();
					break;
				case 'creditors':
					_api = this._creditors_api();
					break;
			}
			if (!_api) return;

			let paginationParams = this.pagination ? {
				length: full ? this.table.total : this.table.per_page,
				page: full ? 1 : this.table.current_page
			} : {};

			const params = {
				...paginationParams,
				...this.params
			};

			if (this.withCount.length > 0) {
				params.relations = this.withCount
			}

			if (this.include.length > 0) {
				params.include = this.include
			}

			const filters = this.filters;
			this.removeEmptyAttributes(filters);

			Object.keys(filters).forEach(key => {
				params[key] = filters[key];
			});

			if (Object.keys(filters).length) {
				params.page = 1;
			}

			try {
				const {
					data: response,
					status,
					statusText,
				} = await _api.get(this.endpoint, {
					params: this.removeEmptyAttributes(params)
				});

				switch (status) {
					case 200:
						if (full) {
							this.tableAction('export', this.pagination ? response.data : response);
						} else {
							if (this.pagination) {
								this.table = response;
							} else {
								this.table.data = response;
							}
						}
						break;
					case 401:
						if (await this.refreshAccessToken()) {
							await this.loadData(full);
						} else {
							this.forceLogout(this);
						}
						break;
					case 403:
						this.emitError('error', status, { title: status, message: response.message });
						break;
					case 404:
						this.emitError('error', status, { title: status, message: response.message });
						break;
					default:
						this.emitError('error', status, { title: status, message: statusText });
						break;
				}

				this.$emit('update', response);
			} catch (ex) {
				this.emitError('error', 500, { title: 'error', message: `Datatable request failed with (${ex.message})` });
			}

			this.loading = false;
		},
		pageChanged(page) {
			this.table.current_page = page;
			this.loadData();
		},
		filterChanged: debounce(($this) => $this.loadData(), 500),
		stickyIfSpreadsheet(spreadsheet, column) {
			if (spreadsheet === 'Offers' || spreadsheet === 'Balance Request' || spreadsheet === 'Creditor Query') {
				if (column === 'MoneyPlus Ref') {
					return 'sticky left-28';
				}
			}
			return '';
		}
	},
}
</script>

<style lang="scss" scoped>
th {
	border-bottom: 1px solid #ddd !important;

	@media (prefers-color-scheme: dark) {
		border-bottom: 1px solid #475569 !important;
	}
}
</style>