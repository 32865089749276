<template>
	<div :class="`rounded-lg flex flex-row mb-4 min-h-12 justify-center w-10/12 px-4 py-4 border-2 ${toastClasses}`">
		<div class="flex w-full grow flex-col items-center justify-center box-border">
			<div class="header flex w-full mb-4 h-6 flex-row text-left justify-start">
				<h3 class="font-medium">
					<fa icon="circle-exclamation" class="mr-2" />
					{{ title }}
				</h3>
				<button @click="$store.commit('clearToast', title)" class="ml-auto cursor-pointer" v-if="!persist">
					<fa icon="xmark" />
				</button>
			</div>
			<div class="flex w-full flex-row text-left justify-start">
				<p class="font-light">
					<fa icon="spinner" class="ml-1 mr-3 fa-pulse" v-if="loading"/>
					<fa icon="angle-right" class="ml-1 mr-3" v-else/>
					{{ message }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { misc } from '../mixins';

export default {
	mixins: [misc],
	props: {
		type: {
			required: true, type: String, validator: function (value) {
				return ['success', 'info', 'warning', 'error', 'default'].indexOf(value) !== -1;
			}
		},
		title: { required: true, type: String },
		message: { required: true, type: String },
		persist: { default: false, type: Boolean },
		loading: { default: false, type: Boolean }
	},
	computed: {
		toastClasses() {
			switch (this.type) {
				case 'success':
					return 'text-green-500 bg-green-100 border-green-300';
				case 'info':
					return 'text-blue-500 bg-blue-100 border-blue-300';
				case 'warning':
					return 'text-yellow-500 bg-yellow-100 border-yellow-300';
				case 'error':
					return 'text-red-500 bg-red-100 border-red-300';
				default:
					return 'text-slate-500 bg-slate-100 border-slate-300';
			}
		}
	},
	created() {
		if(!this.persist) {
			setTimeout(() => this.dismissToast(this.title), 1000 * 5);
		}
	}
}
</script>