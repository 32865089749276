<template>
	<section id="app">
		<router-view />
		<Toasts />
	</section>
</template>

<script>
import Toasts from './components/Toasts.vue';

export default {
	components: { Toasts },
	watch: {
		$route(to) {
			this.$store.commit('updateCurrentPageTitle', { title: to.meta.title });
		}
	},
	beforeCreate() {
		this.$store.dispatch('loadStore');
	}
}
</script>