import axios from 'axios';
import { debounce } from 'lodash';

export default {
	data() {
		return {
			_api: {
				users: {
					key: this.$store.getters.getEnv('VUE_APP_USERS_API_KEY'),
					endpoint: this.$store.getters.getEnv('VUE_APP_USERS_API_URL'),
					timeout: 600000 // 10 minutes
				},
				creditors: {
					key: this.$store.getters.getEnv('VUE_APP_CREDITORS_API_KEY'),
					endpoint: this.$store.getters.getEnv('VUE_APP_CREDITORS_API_URL'),
					timeout: 600000 // 10 minutes
				}
			}
		}
	},
	methods: {
		getTimeoutErrorMessage(error) {
			return `Request (${error.config.method}) to ${error.config.url} has timed out.`;
		},
		_users_api() {
			const headers = {};
			if (this.$store.state.auth.access_token) {
				headers.Authorization = `Bearer ${this.$store.state.auth.access_token}`;
			}
			const instance = axios.create({
				baseURL: this._api.users.endpoint,
				timeout: this._api.users.timeout,
				headers: {
					'x-api-key': this._api.users.key,
					Accept: 'application/json',
					'Content-Type': 'application/json',
					...headers
				}
			});
			instance.interceptors.response.use(response => response, error => {
				if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
					console.log(this.getTimeoutErrorMessage(error));
				}
				return error.response;
			});
			return instance;
		},
		_creditors_api() {
			const headers = {};
			if (this.$store.state.auth.access_token) {
				headers.Authorization = `Bearer ${this.$store.state.auth.access_token}`;
			}
			const params = {};
			if (this.$store.state.creditor.creditor) {
				params.creditor_id = this.$store.state.creditor.creditor.creditor_id;
			}
			const instance = axios.create({
				baseURL: this._api.creditors.endpoint,
				params: {
					...params
				},
				timeout: this._api.creditors.timeout,
				headers: {
					'x-api-key': this._api.creditors.key,
					Accept: 'application/json',
					'Content-Type': 'application/json',
					...headers
				}
			});
			instance.interceptors.response.use(response => response, error => {
				if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
					console.log(this.getTimeoutErrorMessage(error));
				}
				return error.response;
			});
			return instance;
		},
		async refreshAccessToken() {
			const { status } = await this._users_api().post('api/refresh', { refresh_token: this.$store.state.auth.refresh_token });
			switch (status) {
				case 200:
					this.$store.commit('incrementStoreUpdateCount');
					return true;
				case 401:
					this.forceLogout(this);
					return false;
				default:
					return false;
			}
		},
		forceLogout: debounce(($this) => {
			$this.$router.push({ name: 'Logout' });
		}, 500)
	}
}