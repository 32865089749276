export default {
	state: {
		user: null,
		users: []
	},
	mutations: {
		updateUser(state, user) {
			state.user = user;
			localStorage.setItem('user', JSON.stringify(state.user))
		},
		updateUsers(state, users) {
			state.users = users;
		},
		loadUser(state) {
			let User = null;
			if (localStorage.getItem('user')) {
				User = localStorage.getItem('user');
			}
			User = JSON.parse(User);
			if (User) {
				state.user = User;
			}
		},
		resetUser(state) {
			localStorage.removeItem('user');
			state.user = null;
		}
	}
}