import moment from 'moment';
import RandExp from 'randexp';
import { capitalize, replace, startCase, truncate } from 'lodash';

export default {
  data() {
    return {
      scopeDisplay: {
        'Dashboard': [
          { scope: 'creditors::read', enabled: false }
        ],
        'Remittance Files': [
          { scope: 'remittances::list', enabled: false }
        ],
        'Requests': [
          { scope: 'requests::list', enabled: false }
        ],
        'Queries': [
          { scope: 'queries::list', enabled: false }
        ],
        'Ad-hoc Client Information': [
          { scope: 'applications::list', enabled: false }
        ],
        'Missing References': [
          { scope: 'debts::list', enabled: false }
        ],
        'Live Client Listing': [
          { scope: 'applications::index', enabled: false }
        ],
        'Returned Payments': [
          { scope: 'investigations::list', enabled: false }
        ]
      }
    };
  },
  methods: {
    getStringFromRegex(regex, length = 20) {
      return truncate(new RandExp(regex).gen(), { length, omission: '' });
    },
    getDebtReferenceValidationMessage() {
      return 'The debt reference entered does not match our validation, please contact MoneyPlus Group directly - dmcreditorinfo@moneyplus.com.'
    },
    removeEmptyAttributes(obj) {
      for (let prop in obj) {
        if (obj[prop] === '' || obj[prop] === null || obj[prop] === undefined) {
          delete obj[prop];
        }
      }
      return obj;
    },
    removeEmptyAttribute(obj, attribute) {
      if (obj[attribute] === '' || obj[attribute] === null || obj[attribute] === undefined) {
        delete obj[attribute];
      }
      return obj;
    },
    trimAllAttributes(obj) {
      for (let prop in obj) {
        if (typeof obj[prop] === 'string') {
          obj[prop] = obj[prop].trim();
        }
      }
      return obj;
    },
    checkIfExistsInArray(array, value) {
      return array.includes(value);
    },
    getFormDataFromObject(object) {
      const formData = new FormData();
      Object.keys(object).forEach(key => formData.append(key, object[key]));
      return formData;
    },
    getUKDateFormatFromDatetime(datetime, withTime) {
      if (withTime) {
        return this.getMoment(datetime).format('DD/MM/YYYY hh:mma');
      }
      return this.getMoment(datetime).format('DD/MM/YYYY');
    },
    getDateFormatFromDatetime(datetime, withTime) {
      if (withTime) {
        return this.getMoment(datetime).format('YYYY-MM-DD hh:mm:ss');
      }
      return this.getMoment(datetime).format('YYYY-MM-DD');
    },
    getFullDatetime(datetime) {
      return this.getMoment(datetime).format('dddd Do MMMM YYYY (h:mma)');
    },
    getMoment(datetime) {
      return moment(datetime).locale('en-gb');
    },
    capitaliseFirstLetter(string) {
      return capitalize(string);
    },
    capitaliseWords(string) {
      return startCase(string);
    },
    UUID(string, joiner) {
      return string.toLowerCase().split(' ').join(joiner);
    },
    reverseUUID(string, joiner) {
      return replace(string, joiner, ' ');
    },
    getUKNumberFormat(number, withPound) {
      if (withPound) {
        if (number == '0') {
          number = '£0.00';
        } else {
          number = '£' + number;
          if (!number.includes('.')) {
            number = number + '.00';
          }
          if (number.split('.')[1].length === 1) {
            number = number + '0';
          }
        }
      }
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    displayToast(title, type, message, persist = false, loading = false) {
      this.$store.commit('addToast', {
        title: title,
        type: type,
        message: message,
        persist: persist,
        loading: loading
      });
    },
    dismissToast(title) {
      this.$store.commit('clearToast', title);
    },
    emitError(type, status, data) {
      this.$emit('error', {
        type: type,
        status: status,
        data: data,
      });
    },
    tableErrorHandler({ status, data }) {
      switch (status) {
        case 403:
          this.$router.push({ name: 'Dashboard' });
          break;
        case 404:
          this.emitError('not-found', status, { title: status, message: data.message });
          break;
        case 500:
          this.$router.push({ name: 'Requests' });
          break;
        default:
          this.displayToast(data.title.toString(), 'error', data.message);
          break;
      }
    },
    downloadFile(file, filename) {
      const fileURL = window.URL.createObjectURL(new Blob([file]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', filename);
      document.body.appendChild(fileLink);
      fileLink.click();
      document.body.removeChild(fileLink);
    },
    getDebtUpdateDialog(id, mpg_ref, reference, name, balance, preConfirm) {
      this.$swal.fire({
        background: '#f8fafc',
        html: '<div class="flex my-4"><div class="mx-auto fill-indigo-400 dark:fill-indigo-500 text-3xl p-7 border-4 border-indigo-400 dark:border-indigo-500 rounded-full">' + '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg>' + '</div></div>' + '<h2 class="text-3xl font-bold text-black dark:text-gray-200 py-4">Update Debt Reference</h2>' +
          '<div class="text-left space-y-1 mt-2 mb-4">' +
          `<h3 class="dark:text-gray-200"><span class="font-semibold pr-2">Debt ID:</span>${id}</h3>` +
          `<h3 class="dark:text-gray-200"><span class="font-semibold pr-2">MoneyPlus Reference:</span>${mpg_ref}</h3>` +
          `<h3 class="dark:text-gray-200"><span class="font-semibold pr-2">Client Name:</span>${name}</h3>` +
          `<h3 class="dark:text-gray-200"><span class="font-semibold pr-2">Account Balance:</span>${balance}</h3>` +
          '</div>' +
          '<label class="text-left block uppercase text-gray-800 dark:text-gray-200 text-xs font-bold mt-6 mb-2">New Reference</label>',
        input: "text",
        inputValue: reference,
        confirmButtonText: '<span class="flex text-white fill-white"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" class="my-auto mr-2.5"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>Update</span>',
        confirmButtonColor: '#818cf8',
        showCancelButton: true,
        cancelButtonColor: '#ef4444',
        cancelButtonText: '<span class="flex text-white fill-white"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" class="my-auto mr-2.5"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>Cancel</span>',
        preConfirm: preConfirm
      });
    },
    getPasswordVerificationDialog(preConfirm) {
      this.$swal.fire({
        background: '#f8fafc',
        input: 'password',
        html: '<div class="flex my-4"><div class="mx-auto fill-indigo-400 dark:fill-indigo-500 text-3xl p-7 border-4 border-indigo-400 dark:border-indigo-500 rounded-full">' + '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"/></svg>' + '</div></div>' + '<h2 class="text-3xl font-bold text-black dark:text-gray-200 py-4">Authentication</h2>',
        inputPlaceholder: '••••••••••••••••••••••••••••••••••••••••',
        confirmButtonText: '<span class="flex text-white fill-white"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" class="my-auto mr-2.5"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>Validate</span>',
        confirmButtonColor: '#818cf8',
        showCancelButton: true,
        cancelButtonColor: '#ef4444',
        cancelButtonText: '<span class="flex text-white fill-white"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" class="my-auto mr-2.5"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>Cancel</span>',
        preConfirm: preConfirm
      });
    },
    getRequestUploadDialog(preConfirm) {
      this.$swal.fire({
        background: '#f8fafc',
        html: '<div class="flex my-4"><div class="mx-auto fill-indigo-400 dark:fill-indigo-500 text-3xl p-7 border-4 border-indigo-400 dark:border-indigo-500 rounded-full">' + '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/></svg>' + '</div></div>' + '<h2 class="text-3xl font-bold text-black dark:text-gray-200 py-4">Upload Spreadsheet</h2>',
        input: 'file',
        confirmButtonText: '<span class="flex text-white fill-white"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" class="my-auto mr-2.5"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>Upload</span>',
        confirmButtonColor: '#818cf8',
        showCancelButton: true,
        cancelButtonColor: '#ef4444',
        cancelButtonText: '<span class="flex text-white fill-white"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" class="my-auto mr-2.5"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>Cancel</span>',
        preConfirm: preConfirm
      });
    },
    getReturnPaymentReasonDialog({ investigation_id, mpg_ref, client_name, narrative_1, narrative_2, credit, transaction_date, debt_ref }, preConfirm) {
      let reasonResponseInput = HTMLInputElement;
      let newReferenceInputContainer = HTMLInputElement;
      let newReferenceInput = HTMLInputElement;
      let descriptionInputContainer = HTMLInputElement;
      let descriptionInput = HTMLInputElement;
      this.$swal.fire({
        background: '#f8fafc',
        html: `
          <div class="flex my-4">
            <div class="mx-auto fill-indigo-400 dark:fill-indigo-500 text-3xl p-7 border-4 border-indigo-400 dark:border-indigo-500 rounded-full">
              <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
                <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" />
              </svg>
            </div>
          </div>
          <h2 class="text-3xl font-bold text-black dark:text-gray-200 py-4">Specify Reason For The Return</h2>
          <div class="text-left space-y-1 mt-2 mb-4">
            <h3 class="dark:text-gray-200"><span class="font-semibold pr-2">Investigation ID:</span>${investigation_id}</h3>
            <h3 class="dark:text-gray-200"><span class="font-semibold pr-2">MoneyPlus Reference:</span>${mpg_ref}</h3>
            <h3 class="dark:text-gray-200"><span class="font-semibold pr-2">Client Name:</span>${client_name}</h3>
            <h3 class="dark:text-gray-200"><span class="font-semibold pr-2">Narrative #1:</span>${narrative_1}</h3>
            <h3 class="dark:text-gray-200"><span class="font-semibold pr-2">Narrative #2:</span>${narrative_2}</h3>
            <h3 class="dark:text-gray-200"><span class="font-semibold pr-2">Credit:</span>${credit}</h3>
            <h3 class="dark:text-gray-200"><span class="font-semibold pr-2">Transaction Date:</span>${transaction_date}</h3>
            <h3 class="dark:text-gray-200"><span class="font-semibold pr-2">Debt Reference:</span>${debt_ref}</h3>
          </div>
          <div class="text-left space-y-1 mt-2 mb-4">
            <div id="reason_response_container">
              <h3 class="dark:text-gray-200"><span class="font-semibold pr-2">Select Reason</span></h3>
              <select id="reason_response" name="reason_response" class="mt-1 border-2 border-indigo-400 focus:border-indigo-400 dark:border-indigo-500 dark:focus:border-indigo-500 bg-indigo-100 dark:bg-indigo-800 text-sm font-medium rounded-lg focus:ring-0 block w-full text-black dark:text-gray-200">
                <option selected disabled>Select option</option>
                <option>Incorrect reference</option>
                <option>No longer dealing</option>
                <option>Paid in full/Written off</option>
                <option>Other</option>
              </select>
            </div>
            <div id="new_reference_container">
              <h3 class="dark:text-gray-200"><span class="font-semibold pr-2">New Debt Reference</span></h3>
              <input type="text" id="new_reference" name="new_reference" class="mt-2 rounded-lg border-1 border-slate-200 dark:border-0 focus:ring-0 placeholder-black dark:placeholder-gray-300 text-black dark:text-gray-200 bg-white dark:bg-slate-500 w-full block text-sm p-2.5" required>
            </div>
            <div id="description_container">
              <h3 class="dark:text-gray-200"><span class="font-semibold pr-2">Description</span></h3>
              <textarea id="description" name="description" rows="4" class="mt-2 block p-2.5 w-full text-sm rounded-lg placeholder-gray-300 text-gray-600 dark:text-gray-200 bg-white dark:bg-slate-500 border-1 dark:border-0 focus:ring-0" placeholder="Write your reason here ..." required></textarea>
            </div>
          `,
        confirmButtonText: '<span class="flex text-white fill-white"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" class="my-auto mr-2.5"><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>Update</span>',
        confirmButtonColor: '#818cf8',
        showCancelButton: true,
        cancelButtonColor: '#ef4444',
        cancelButtonText: '<span class="flex text-white fill-white"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" class="my-auto mr-2.5"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>Cancel</span>',
        didOpen: () => {
          const popup = this.$swal.getPopup();
          reasonResponseInput = popup.querySelector('#reason_response');
          newReferenceInputContainer = popup.querySelector('#new_reference_container');
          newReferenceInput = popup.querySelector('#new_reference');
          descriptionInputContainer = popup.querySelector('#description_container');
          descriptionInput = popup.querySelector('#description');
          newReferenceInputContainer.style.display = 'none';
          descriptionInputContainer.style.display = 'none';
          reasonResponseInput.addEventListener('input', (event) => {
            newReferenceInputContainer.style.display = 'none';
            newReferenceInput.value = null;
            descriptionInputContainer.style.display = 'none';
            descriptionInput.value = null;
            switch (event.target.value) {
              case 'Incorrect reference':
                newReferenceInputContainer.style.display = 'block';
                break;
              case 'No longer dealing':
              case 'Other':
                descriptionInputContainer.style.display = 'block';
                break;
            }
          });
        },
        preConfirm: async () => {
          const obj = {
            investigation_id,
            reason_response: reasonResponseInput.value,
            new_reference: newReferenceInput.value,
            description: descriptionInput.value
          };
          if (obj.reason_response === 'Incorrect reference' && obj.new_reference === '') {
            this.$swal.showValidationMessage('Reference is required');
            return false;
          }
          if (['No longer dealing', 'Other'].includes(obj.reason_response) && obj.description === '') {
            this.$swal.showValidationMessage('Please enter the description.');
            return false;
          }
          return await preConfirm(obj)
        }
      });
    },
    hasScope(scopes, scope) {
      return scopes.includes(scope);
    }
  }
}