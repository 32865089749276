<template>
	<TransitionGroup>
		<div class="flex fixed items-center flex-col top-6 -right-7 w-4/12 min-h-[150px] z-[9999]" v-if="toasts.length">
			<Toast :key="index" v-for="({ type, title, message, persist, loading }, index) of toasts" :title="title" :message="message"
				:type="type" :persist="persist" :loading="loading" />
		</div>
	</TransitionGroup>
</template>

<script>
import Toast from './Toast.vue';

export default {
	components: { Toast },
	computed: {
		toasts() {
			return this.$store.state.toasts;
		}
	}
}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
	transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}
</style>